import React from "react"
import Layout from "../components/layout"


export default () => (
    <Layout>

    <h1>Shop Content</h1>
    <p><img src="/shoppage.jpg" alt="shopping page image" /></p>
  </Layout>
)